import React from "react";
import { OriginContent, OriginContentWrapper } from "./Origin.styles";

function Origin() {
  return (
    <OriginContentWrapper disableBackground={true}>
      <h2>The Short Version:</h2>
      <OriginContent disableBackground={true}>
        <p>
          While other kids dreamt of being astronauts, doctors, or rock stars, I
          was the weird one who wanted to make commercials.
        </p>
        <p>
          Not <em>star</em> in them — <em>make</em> them.
        </p>
        <p>
          That fascination with crafting messages stuck with me, and kickstarted
          a two-decade career through the wild and ever-changing landscape of
          marketing.
        </p>
        <p>
          Now, I'm using that experience to helping (game) developers succeed.
        </p>
      </OriginContent>
    </OriginContentWrapper>
  );
}

export default Origin;

// stores/slices/loadingSlice.js

const createLoadingSlice = (set, get) => ({
  loadingStates: {
    app: false,
    scene: false,
    data: false,
    transition: false,
  },
  activeLoadingComponents: new Set(),

  setLoading: (component, isLoading) => {
    set((state) => {
      const newSet = new Set(state.activeLoadingComponents);
      if (isLoading) {
        newSet.add(component);
      } else {
        newSet.delete(component);
      }

      return {
        activeLoadingComponents: newSet,
        loadingStates: {
          ...state.loadingStates,
          [component]: isLoading,
        },
      };
    });
  },

  isAnyLoading: () => {
    const state = get();
    return (
      state.activeLoadingComponents.size > 0 ||
      Object.values(state.loadingStates).some(Boolean)
    );
  },

  // Utility hook for components
  useComponentLoading: (componentName) => {
    const setLoading = (isLoading) => {
      get().setLoading(componentName, isLoading);
    };
    return setLoading;
  },
});

export default createLoadingSlice;

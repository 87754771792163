import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useViewport } from "../../../context/ViewportContext";
import useStore from "../../../stores/store";
import {
  Wrapper,
  Content,
  ContentWrapper,
  ScrollContent,
  StyledImage,
} from "../../../components/BentoBox/StyledComponents";

import { BentoBox, BentoItem } from "../../../components/BentoBox/BentoBox";
import { breakpoints, media } from "../../../styles/breakpoints";
import Scrollbar from "../../../components/Scrollbar/Scrollbar";
import Hero from "../../../components/BentoBox/Hero";
import { preloadBackgroundImages, preloadImages } from "../utils";
import styled from "styled-components";
import SplitContent from "./Disclaimer/SplitContent";

const Title = styled.h1`
  && {
    font-size: calc(4.5 * (1vh + 1vw));
    line-height: calc(3.25 * (1vh + 1vw));
    text-align: right;

    ${media.between("laptopL", "desktop")`
      font-size: calc(5.25 * (1vh + 1vw));
      line-height: calc(3.75 * (1vh + 1vw));
    `}

    ${media.between("laptopS", "laptopL")`
      font-size: clamp(2.5rem, 5vw + 1rem, 5.5rem);
      line-height: clamp(1.5rem, 3vw + 1rem, 3.5rem);
    `}

    ${media.between("tabletL", "laptopS")`
      font-size: calc(3.75 * (1vh + 1vw));
      line-height: calc(3 * (1vh + 1vw));
    `}

    ${media.between("tabletM", "tabletL")`
      font-size: clamp(2.5rem, 5vw + 1rem, 5.5rem);
      line-height: clamp(1.5rem, 3vw + 1rem, 3.5rem);
    `}

    ${media.between("tabletS", "tabletM")`
      font-size: clamp(2.5rem, 10vw + 1rem, 10.5rem);
      line-height: clamp(3.25rem, 3vw + 1rem, 5.5rem);
    `}


    ${media.down("tabletS")`
      font-size: calc(4.25 * (1vh + 1vw));
      line-height: calc(3.75 * (1vh + 1vw));
      margin-bottom: 0;
    `}
  }
`;

const GridLayout = styled.div`
  display: grid;
  gap: 1rem;
  width: 100%;
  grid-column: 1/-1;
  grid-row: auto;

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    margin-top: 0;
  `}
`;

const Grid5x1 = styled(GridLayout)`
  grid-template-columns: repeat(5, 1fr);

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}
`;

const Grid3x2 = styled(GridLayout)`
  grid-template-columns: repeat(3, 1fr);

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}
`;

const SplitLayout = styled.div`
  display: grid;
  gap: 1rem;
  grid-column: 1/-1;

  ${media.down("tabletM")`
    grid-template-columns: 1fr;
  `}
`;

const SplitMainAndSidebar = styled(SplitLayout)`
  grid-template-columns: ${(props) =>
    props.reverseOrder ? "1fr 1fr" : "1fr 1fr"};
  direction: ${(props) => (props.reverseOrder ? "rtl" : "ltr")};

  > * {
    direction: ltr; // Reset direction for children
  }

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: 1 / -1;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    direction: ltr;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    direction: ltr;
  `}
`;

const Grid2x2 = styled(GridLayout)`
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1;

  ${media.down("tabletS")`
    grid-template-columns: 1fr;
  `}
`;

const Grid3x3 = styled(GridLayout)`
  grid-template-columns: repeat(3, 1fr);
  grid-column: 1;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
  `}
`;

const FullWidthItem = styled(BentoItem)`
  grid-column: 1/-1;
  height: 100%;

  ${media.down("tabletS")`
    grid-column: 2/-1;
    margin-right: 0.5rem;
  `}
`;

const GridItem = styled(BentoItem)`
  height: 100%;

  ${media.between("mobileL", "tabletL")`
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    margin-right: 0.5rem;
  `}
`;

const MainContent = styled(BentoItem)`
  height: 100%;
  align-content: center;
  justify-self: center;
  width: 80%;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    margin-right: 0.5rem;
    width: 95%;
  `}

  ${media.down("mobileL")`
    margin-right: 0.5rem;
    width: 95%;
  `}
`;

const ImageItem = styled(BentoItem)`
  height: 100%;

  ${media.down("tabletS")`
    margin-right: 0.5rem;
  `}
`;

const Portraiture = () => {
  const [isLoading, setIsLoading] = useState(true);
  const viewport = useViewport();
  const { width, height } = viewport;
  const [orientationKey, setOrientationKey] = useState(0);
  const [shouldShowScrollbar, setShouldShowScrollbar] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(viewport.isLandscape);
  const { theme } = useStore();

  const { images, fetchImages } = useStore();

  const contentKey = useMemo(() => {
    return `portrait-${viewport.width}-${viewport.height}-${isLandscape}`;
  }, [viewport.width, viewport.height, isLandscape]);

  const updateDimensions = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const contentHeight = contentRef.current.scrollHeight;
      setShouldShowScrollbar(contentHeight > containerHeight);
    }
  }, []);

  useEffect(() => {
    const loadImagesAndPreload = async () => {
      try {
        await fetchImages("photography", "portraiture");
        await Promise.all([
          preloadImages(".grid__img"),
          preloadBackgroundImages(".grid__img"),
        ]);
        setIsLoading(false);
        window.scrollTo(0, 0);
        updateDimensions();
      } catch (error) {
        console.error("Error loading images:", error);
        setIsLoading(false);
      }
    };

    loadImagesAndPreload();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [fetchImages, updateDimensions]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientationKey((prev) => prev + 1);
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    updateDimensions();
  }, [viewport, isLandscape, updateDimensions]);

  const leftMargin = useMemo(() => {
    if (!viewport) return "5.25rem";
    const { width } = viewport;

    if (width < breakpoints.mobileL) return "3rem";
    if (width < breakpoints.tabletL) return "3.5rem";
    if (width < breakpoints.laptopS) return "5.25rem";
    if (width < breakpoints.desktop) return "5.25rem";
    return "5.25rem";
  }, [viewport]);

  const gridImages = images.map((image) => image.file);

  return (
    <Wrapper theme={theme}>
      <Content>
        <Scrollbar
          scrollbarPosition="left"
          theme={theme}
          applyScrolling={true}
          forceScrollbar={true}
          trackMargin={{
            top: "0",
            bottom: "0",
            left: leftMargin,
            right: "0",
          }}
        >
          <ScrollContent>
            <ContentWrapper>
              <BentoBox>
                <Hero image={gridImages[1]}>
                  <Title>Editorial Portraiture</Title>
                </Hero>
                <FullWidthItem>
                  <StyledImage src={gridImages[33]} />
                </FullWidthItem>
                <SplitContent headline="Disclaimer" image={gridImages[0]}>
                  <p>
                    As with the fitness gallery, When your data storage solution
                    decides to throw a tantrum and eat large chunks your life's
                    work, you learn to appreciate what's left.
                  </p>
                  <p>For my old portraiture work, this is what's left.</p>
                </SplitContent>
                <Grid5x1>
                  {gridImages.slice(2, 7).map((image, index) => (
                    <GridItem key={index}>
                      <StyledImage src={image} />
                    </GridItem>
                  ))}
                </Grid5x1>
                <SplitContent image={gridImages[7]} imageOnLeft={false}>
                  <p>
                    Early in my marketing career, when I left agency life and
                    struck out on my own, I leaned heavily on the Addy and
                    MarComm awards I'd just won, and my growing skill with the
                    camera, to get off the ground.
                  </p>
                  <p>
                    I'd just moved back home (to Kansas City) after some years
                    away, and my research showed a gap in the portrait market.
                  </p>
                  <p>
                    Long story short, I created and launched a campaign that
                    ended up getting me voted (by the community) as the best
                    portrait photographer in the city.
                  </p>
                  <p>Then top100photographers.com (now defunct) listed me.</p>
                  <p>
                    Sadly, I neglected to screencap any of that back in 2009,
                    so... lol trust me bro.
                  </p>
                  <p>But that's what happened.</p>
                </SplitContent>
                <SplitMainAndSidebar>
                  <Grid2x2>
                    {gridImages.slice(11, 15).map((image, index) => (
                      <GridItem key={index}>
                        <StyledImage src={image} />
                      </GridItem>
                    ))}
                  </Grid2x2>
                  <MainContent disableBackground={true}>
                    <p>
                      Much of the work you see in this gallery came from those
                      early days spent turning all that publicity into a solid
                      client roster. I made happy customers, they spread the
                      word.
                    </p>
                    <p>
                      Your average professional photographer has no idea how
                      they really fit into the marketing machine — so they can't
                      connect their work to ROI.
                    </p>
                    <p>I did, and it made all the difference.</p>
                  </MainContent>
                </SplitMainAndSidebar>
                <FullWidthItem>
                  <StyledImage src={gridImages[15]} />
                </FullWidthItem>
                <Grid3x2>
                  {gridImages.slice(16, 19).map((image, index) => (
                    <GridItem key={index}>
                      <StyledImage src={image} />
                    </GridItem>
                  ))}
                </Grid3x2>
                <FullWidthItem>
                  <StyledImage src={gridImages[25]} />
                </FullWidthItem>
                <SplitMainAndSidebar reverseOrder>
                  <Grid3x3>
                    {gridImages.slice(19, 25).map((image, index) => (
                      <GridItem key={index}>
                        <StyledImage src={image} />
                      </GridItem>
                    ))}
                  </Grid3x3>
                  <MainContent disableBackground={true}>
                    <p>
                      Within a year or two, I niched hard toward fitness
                      competitors.
                    </p>
                    <p>That work is in another gallery.</p>
                    <p>
                      Long story short, eventually I stopped accepting
                      "portrait" assignments like what you see here.
                    </p>
                  </MainContent>
                </SplitMainAndSidebar>
                <FullWidthItem>
                  <StyledImage src={gridImages[32]} />
                </FullWidthItem>
                <SplitMainAndSidebar>
                  <Grid3x3>
                    {gridImages.slice(26, 32).map((image, index) => (
                      <GridItem key={index}>
                        <StyledImage src={image} />
                      </GridItem>
                    ))}
                  </Grid3x3>
                  <MainContent disableBackground={true}>
                    <p>
                      I never expected to work in photography as long as I did,
                      and what's clear to me as I reflect on this part of my
                      story, is a deep appreciation for the power of personal
                      story.
                    </p>
                    <p>Someone great once said something like:</p>
                    <p>
                      "If you could experience another person's life, start to
                      finish, in every detail — at the end of that experience,
                      the only possible response would be love."
                    </p>
                    <p>I'm inclined to agree.</p>
                  </MainContent>
                </SplitMainAndSidebar>
                <FullWidthItem>
                  <StyledImage src={gridImages[34]} />
                </FullWidthItem>
              </BentoBox>
            </ContentWrapper>
          </ScrollContent>
        </Scrollbar>
      </Content>
    </Wrapper>
  );
};

export default Portraiture;

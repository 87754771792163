import { Howl } from "howler";

const S3_BUCKET_URL = "https://site-davidbickley.s3.us-east-2.amazonaws.com";

// Helper function to create consistent error handling
const createAudioErrorHandler = (soundName) => (id, error) => {
  console.error(`Error with ${soundName} sound:`, error);
  // Could add error reporting here
};

// Helper function to create consistent load handling
const createAudioLoadHandler = (soundName) => () => {
  console.log(`${soundName} sound loaded successfully`);
};

export const audio = {
  shoot: new Howl({
    src: `${S3_BUCKET_URL}/audio/AsteroidGame/shoot.mp3`,
    volume: 0.1,
    onload: createAudioLoadHandler("shoot"),
    onloaderror: createAudioErrorHandler("shoot"),
    onplayerror: createAudioErrorHandler("shoot-playback"),
  }),
  damageTaken: new Howl({
    src: `${S3_BUCKET_URL}/audio/AsteroidGame/damage.mp3`,
    volume: 0.1,
    onload: createAudioLoadHandler("damage"),
    onloaderror: createAudioErrorHandler("damage"),
    onplayerror: createAudioErrorHandler("damage-playback"),
  }),
  explosion: new Howl({
    src: `${S3_BUCKET_URL}/audio/AsteroidGame/explode.mp3`,
    volume: 0.2,
    onload: createAudioLoadHandler("explosion"),
    onloaderror: createAudioErrorHandler("explosion"),
    onplayerror: createAudioErrorHandler("explosion-playback"),
  }),
  death: new Howl({
    src: `${S3_BUCKET_URL}/audio/AsteroidGame/death.mp3`,
    volume: 0.1,
    onload: createAudioLoadHandler("death"),
    onloaderror: createAudioErrorHandler("death"),
    onplayerror: createAudioErrorHandler("death-playback"),
  }),
  powerUp: new Howl({
    src: `${S3_BUCKET_URL}/audio/AsteroidGame/powerUp.mp3`,
    volume: 0.1,
    onload: createAudioLoadHandler("powerUp"),
    onloaderror: createAudioErrorHandler("powerUp"),
    onplayerror: createAudioErrorHandler("powerUp-playback"),
  }),
  select: new Howl({
    src: `${S3_BUCKET_URL}/audio/AsteroidGame/select.mp3`,
    volume: 0.1,
    onload: createAudioLoadHandler("select"),
    onloaderror: createAudioErrorHandler("select"),
    onplayerror: createAudioErrorHandler("select-playback"),
  }),
  backgroundMusic: new Howl({
    src: `${S3_BUCKET_URL}/audio/AsteroidGame/backgroundMusic.mp3`,
    volume: 0.2,
    loop: true,
    html5: true,
    preload: true,
    onload: createAudioLoadHandler("backgroundMusic"),
    onloaderror: createAudioErrorHandler("backgroundMusic"),
    onplayerror: createAudioErrorHandler("backgroundMusic-playback"),
    onend: () => {
      console.log("Background music ended - attempting to restart");
      // Attempt to restart if loop somehow failed
      if (!audio.backgroundMusic.playing()) {
        audio.backgroundMusic.play();
      }
    },
    onstop: () => {
      console.log("Background music stopped");
    },
    onpause: () => {
      console.log("Background music paused");
    },
  }),
};

// Add methods to manage background music specifically
audio.backgroundMusic.restart = function () {
  this.stop();
  setTimeout(() => {
    this.play();
  }, 50);
};

// Add method to safely clean up all audio
export const cleanupAudio = () => {
  Object.values(audio).forEach((sound) => {
    if (sound.playing()) {
      sound.stop();
    }
    sound.unload();
  });
};

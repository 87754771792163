// stores/slices/caseStudySlice.js

import { queryClient } from "../store";
import * as CaseStudyService from "../../services/CaseStudyService";

const createCaseStudySlice = (set, get) => ({
  currentCaseStudy: null,
  caseStudies: [],

  setCurrentCaseStudy: (caseStudy) => set({ currentCaseStudy: caseStudy }),
  setCaseStudies: (caseStudies) => set({ caseStudies }),

  fetchCaseStudy: async (slug) => {
    const setLoading = get().useComponentLoading("data");
    setLoading(true);
    try {
      const caseStudy = await queryClient.fetchQuery(["caseStudy", slug], () =>
        CaseStudyService.fetchCaseStudy(slug)
      );
      set({ currentCaseStudy: caseStudy });
      return caseStudy;
    } catch (error) {
      console.error("Error fetching case study:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  },

  fetchCaseStudies: async () => {
    const setLoading = get().useComponentLoading("data");
    setLoading(true);
    try {
      const caseStudies = await queryClient.fetchQuery(
        "caseStudies",
        CaseStudyService.fetchCaseStudies
      );
      set({ caseStudies });
      return caseStudies;
    } catch (error) {
      console.error("Error fetching case studies:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  },

  refetchCaseStudies: () => queryClient.invalidateQueries("caseStudies"),
});

export default createCaseStudySlice;

// Blasteroid.jsx

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Player,
  Enemy,
  Projectile,
  Particle,
  BackgroundParticle,
  PowerUp,
} from "./src/Classes";
import { audio, cleanupAudio } from "./src/components/Audio";
import { gsap } from "gsap";
import useStore from "../../../../stores/store";
import { hexToRgba } from "../../../../utils/hexToRGBA";
import Button from "../../../../components/Button/Button";
import { hexToHSL } from "../../../../utils/hexToHSL";
import { media } from "../../../../styles/breakpoints";

const GameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: ${({ theme }) => theme.tertiaryColor};
  overflow: hidden; // Add this to prevent overflow

  canvas {
    display: block; // Add this to remove any extra space
    width: 100%;
    height: 100%;
    max-width: 100%; // Add this to prevent horizontal overflow
    object-fit: contain; // Add this to maintain aspect ratio if needed
  }
`;

const Score = styled.div`
  position: absolute;
  top: 85px;
  left: 85px;
  color: ${({ theme }) => theme.textColorPrimary};
  padding: 8px;
  font-size: 1.25rem;
  font-weight: bold;
  user-select: none;

  ${media.down("mobileL")`
    top: 3rem;
    left: 3rem;
    
    svg {
      width: 25px;
      height: 25px;
    }
  `}

  ${media.between("mobileL", "tabletL")`
    top: 3.5rem;
    left: 3em;
    
    svg {
      width: 25px;
      height: 25px;
    }
  `}
`;

const ScoreLabel = styled.div`
  position: fixed;
  color: ${({ theme }) => theme.textColorPrimary};
  user-select: none;
  pointer-events: none;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
`;

const VolumeControl = styled.div`
  position: absolute;
  bottom: 4.5rem;
  left: 1rem;
  padding: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor};

  svg {
    width: 35px;
    height: 35px;
  }

  ${media.down("mobileL")`
    bottom: 3rem;
    left: 0.25rem;
    
    svg {
      width: 25px;
      height: 25px;
    }
  `}

  ${media.between("mobileL", "tabletL")`
    bottom: 3.5rem;
    left: 0.5rem;
    
    svg {
      width: 25px;
      height: 25px;
    }
  `}

  ${media.between("tabletL", "laptopS")`
  `}

  ${media.up("laptopS")`
  `}
`;

const Modal = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.bodyBgPrimary};
  top: 50%;
  left: 50%;
  padding: 1.5rem 1rem 2.5rem 1rem;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 300px;
  text-align: center;
  border-radius: 5px;
  color: ${({ theme }) => theme.textColorPrimary};
  display: ${(props) => (props.show ? "block" : "none")};

  h1 {
    font-size: 2.5rem;
    margin-bottom: 0;
  }
  h2 {
    font-size: 1rem;
    font-weight: normal;
    font-style: italic;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  ${media.down("mobileL")`
    max-width: 80%;
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 1.5rem;
`;

const FloatingScore = ({ position, value, onComplete }) => {
  const labelRef = useRef(null);

  useEffect(() => {
    if (labelRef.current) {
      gsap.to(labelRef.current, {
        opacity: 0,
        y: -30,
        duration: 0.75,
        onComplete: () => {
          if (onComplete) onComplete();
        },
      });
    }
  }, []);

  return (
    <ScoreLabel
      ref={labelRef}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    >
      +{value}
    </ScoreLabel>
  );
};

const Blasteroid = () => {
  const canvasRef = useRef(null);
  const powerUpRef = useRef(null);
  const [score, setScore] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [scoreLabels, setScoreLabels] = useState([]);
  const [showStartModal, setShowStartModal] = useState(true);
  const [audioMuted, setAudioMuted] = useState(false);
  const [game, setGame] = useState({ active: false });
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const { theme } = useStore();

  const gameState = useRef({
    enemies: [],
    projectiles: [],
    particles: [],
    powerUps: [],
    backgroundParticles: [],
    frames: 0,
    audioInitialized: false,
    player: null,
    animationId: null,
    intervalId: null,
    spawnPowerUpsId: null,
    currentThemeColor: theme.primaryColor,
    mouse: {
      x: 0,
      y: 0,
      clicked: false,
    },
    isMouseDown: false,
  });

  // Add keyboard controls
  const handleKeyDown = (event) => {
    if (!gameState.current.player) return;

    const acceleration = 0.5; // Smaller acceleration for more controlled movement

    switch (event.key.toLowerCase()) {
      case "arrowright":
      case "d":
        gameState.current.player.velocity.x += acceleration;
        break;
      case "arrowleft":
      case "a":
        gameState.current.player.velocity.x -= acceleration;
        break;
      case "arrowup":
      case "w":
        gameState.current.player.velocity.y -= acceleration;
        break;
      case "arrowdown":
      case "s":
        gameState.current.player.velocity.y += acceleration;
        break;
      default:
        break;
    }
  };

  // Update mouse position tracking
  const handleMouseMove = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    gameState.current.mouse.x = event.clientX - rect.left;
    gameState.current.mouse.y = event.clientY - rect.top;
  };

  // Update click handling
  const handleClick = (event) => {
    if (!gameState.current.active) {
      return;
    }

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;

    shoot({ x: clickX, y: clickY });
  };

  // Update touch handling
  const handleTouchStart = (event) => {
    if (!game.active) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const touchX = event.touches[0].clientX - rect.left;
    const touchY = event.touches[0].clientY - rect.top;

    gameState.current.mouse.x = touchX;
    gameState.current.mouse.y = touchY;

    shoot({ x: touchX, y: touchY });
  };

  const handleTouchMove = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    gameState.current.mouse.x = event.touches[0].clientX - rect.left;
    gameState.current.mouse.y = event.touches[0].clientY - rect.top;
  };

  const handleResize = () => {
    canvasRef.width = window.innerWidth;
    canvasRef.height = window.innerHeight;
    init();
  };

  const createScoreLabel = ({ position, score }) => {
    const id = Date.now();
    setScoreLabels((prev) => [
      ...prev,
      {
        id,
        position: {
          x: position.x,
          y: position.y,
        },
        score,
      },
    ]);

    // Remove the score label after animation completes
    setTimeout(() => {
      setScoreLabels((prev) => prev.filter((label) => label.id !== id));
    }, 750);
  };

  const init = (newGameState = { active: false }) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const parent = canvas.parentElement;
    canvas.width = parent.clientWidth;
    canvas.height = parent.clientHeight;

    const x = canvas.width / 2;
    const y = canvas.height / 2;

    // Update properties instead of overwriting the entire object
    gameState.current.player = new Player(x, y, 10, theme.textColorPrimary);
    gameState.current.enemies = [];
    gameState.current.projectiles = [];
    gameState.current.particles = [];
    gameState.current.powerUps = [];
    gameState.current.frames = 0;
    gameState.current.backgroundParticles = [];
    gameState.current.audioInitialized = false;
    gameState.current.animationId = null;
    gameState.current.intervalId = null;
    gameState.current.spawnPowerUpsId = null;
    gameState.current.active = newGameState.active;
    setGame({ active: newGameState.active });
    gameState.current.mouse = {
      x: 0,
      y: 0,
      clicked: false,
    };

    // Create background particles
    const backgroundParticleSpacing = 25;
    for (
      let x = 0;
      x < canvas.width + backgroundParticleSpacing;
      x += backgroundParticleSpacing
    ) {
      for (
        let y = 0;
        y < canvas.height + backgroundParticleSpacing;
        y += backgroundParticleSpacing
      ) {
        gameState.current.backgroundParticles.push(
          new BackgroundParticle({
            position: { x, y },
            radius: 3,
            color: theme.primaryColor,
          })
        );
      }
    }

    setScore(0);
    setGame({ active: true });
  };

  const initializeAudio = () => {
    if (!isAudioInitialized) {
      // Create and resume audio context
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext();

      // Ensure audio context is running
      if (audioContext.state === "suspended") {
        audioContext.resume();
      }

      // Initialize all audio elements
      Object.values(audio).forEach((sound) => {
        sound.load();
        // Ensure audio is ready to play
        if (sound.audio) {
          sound.audio.load();
        }
      });

      setIsAudioInitialized(true);
      gameState.current.audioInitialized = true;
    }
  };

  // Add visibility handler
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Clear intervals when inactive
        if (gameState.current.intervalId) {
          clearInterval(gameState.current.intervalId);
        }
        if (gameState.current.spawnPowerUpsId) {
          clearInterval(gameState.current.spawnPowerUpsId);
        }

        // Pause background music
        if (!audioMuted && audio.backgroundMusic) {
          try {
            audio.backgroundMusic.pause();
          } catch (err) {
            console.log("Failed to pause background music:", err);
          }
        }
      } else {
        // Only restart if game was active
        if (game.active) {
          // Respawn enemies and powerups
          spawnEnemies();
          spawnPowerUps();

          // Resume background music if it was playing
          if (!audioMuted && audio.backgroundMusic) {
            try {
              audio.backgroundMusic.play();
            } catch (err) {
              console.log("Failed to resume background music:", err);
            }
          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [game.active, audioMuted]);

  // Function to generate color variation
  function generateColorVariation(baseColor) {
    const hsl = hexToHSL(baseColor);

    // Generate random variations
    const hueVariation = 20; // Adjust this value to control how much the hue can vary
    const saturationVariation = 20; // Adjust for saturation variation

    const newHue =
      (hsl.h + Math.random() * hueVariation * 2 - hueVariation) % 360;
    const newSaturation = Math.max(
      0,
      Math.min(
        100,
        hsl.s + Math.random() * saturationVariation * 2 - saturationVariation
      )
    );

    return `hsl(${newHue}, ${newSaturation}%, 50%)`; // Keep lightness constant at 50%
  }

  const spawnEnemies = () => {
    const canvas = canvasRef.current;
    gameState.current.intervalId = setInterval(() => {
      const radius = Math.random() * (30 - 4) + 4;
      let x, y;

      if (Math.random() < 0.5) {
        x = Math.random() < 0.5 ? 0 - radius : canvas.width + radius;
        y = Math.random() * canvas.height;
      } else {
        x = Math.random() * canvas.width;
        y = Math.random() < 0.5 ? 0 - radius : canvas.height + radius;
      }

      // Use the stored theme color
      const color = generateColorVariation(gameState.current.currentThemeColor);

      const angle = Math.atan2(canvas.height / 2 - y, canvas.width / 2 - x);
      const velocity = {
        x: Math.cos(angle),
        y: Math.sin(angle),
      };

      gameState.current.enemies.push(new Enemy(x, y, radius, color, velocity));
    }, 1000);
  };

  const spawnPowerUps = () => {
    const canvas = canvasRef.current;

    const spawnWithRandomDelay = () => {
      // Random delay between 10000ms (10s) and 20000ms (20s)
      const randomDelay = Math.random() * (15000 - 10000) + 10000;

      gameState.current.spawnPowerUpsId = setTimeout(() => {
        const radius = 15;
        // Spawn from random edge of screen
        let x, y;
        if (Math.random() < 0.5) {
          x = Math.random() < 0.5 ? 0 - radius : canvas.width + radius;
          y = Math.random() * canvas.height;
        } else {
          x = Math.random() * canvas.width;
          y = Math.random() < 0.5 ? 0 - radius : canvas.height + radius;
        }

        const angle = Math.atan2(canvas.height / 2 - y, canvas.width / 2 - x);
        const velocity = {
          x: Math.cos(angle),
          y: Math.sin(angle),
        };

        gameState.current.powerUps.push(
          new PowerUp({
            position: { x, y },
            velocity: {
              x: velocity.x * 2,
              y: velocity.y * 2,
            },
            color: theme.accentColorPrimary,
          })
        );

        // Schedule next spawn
        spawnWithRandomDelay();
      }, randomDelay);
    };

    // Start the first spawn
    spawnWithRandomDelay();
  };

  function shoot({ x, y }) {
    // Return early if game isn't active or player doesn't exist
    if (!gameState.current.active || !gameState.current.player) {
      return;
    }

    const player = gameState.current.player;
    const angle = Math.atan2(y - player.y, x - player.x);

    const velocity = {
      x: Math.cos(angle) * 5,
      y: Math.sin(angle) * 5,
    };

    gameState.current.projectiles.push(
      new Projectile(player.x, player.y, 5, theme.accentColorPrimary, velocity)
    );

    // Play sound only if not muted
    if (!audioMuted && audio.shoot) {
      try {
        audio.shoot.currentTime = 0;
        audio.shoot.play();
      } catch (err) {
        console.log("Audio error:", err);
      }
    }
  }

  // Set the current theme color
  useEffect(() => {
    gameState.current.backgroundColor = hexToRgba(theme.bodyBgPrimary, 0.1);
    gameState.current.currentThemeColor = theme.primaryColor;

    // Only update player color if there's no active powerUp
    if (gameState.current.player && !powerUpRef.current) {
      gameState.current.player.color = theme.textColorPrimary;
    }

    if (gameState.current.enemies) {
      gameState.current.enemies.forEach((enemy) => {
        enemy.color = generateColorVariation(theme.primaryColor);
      });
    }
  }, [theme]);

  const animate = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext("2d");
    const {
      player,
      powerUps,
      projectiles,
      particles,
      enemies,
      backgroundParticles,
      backgroundColor,
    } = gameState.current;

    // Add safety check
    if (!player) {
      return;
    }

    gameState.current.animationId = requestAnimationFrame(animate);
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);
    gameState.current.frames++;

    // Draw and update background particles
    backgroundParticles.forEach((backgroundParticle) => {
      backgroundParticle.draw(context);

      const dist = Math.hypot(
        player.x - backgroundParticle.position.x,
        player.y - backgroundParticle.position.y
      );

      if (dist < 100) {
        backgroundParticle.alpha = 0;
        if (dist > 70) {
          backgroundParticle.alpha = 0.25;
        }
      } else if (dist > 100 && backgroundParticle.alpha < 0.025) {
        backgroundParticle.alpha += 0.01;
      } else if (dist > 100 && backgroundParticle.alpha > 0.025) {
        backgroundParticle.alpha -= 0.01;
      }
    });

    player.update(context, canvas);

    if (gameState.current.isMouseDown && player.canShoot()) {
      shoot({
        x: gameState.current.mouse.x,
        y: gameState.current.mouse.y,
      });
    }

    // Update power-ups
    for (let i = powerUps.length - 1; i >= 0; i--) {
      const powerUp = powerUps[i];

      // Remove if off screen
      if (
        powerUp.position.x > canvas.width ||
        powerUp.position.x < 0 - powerUp.radius * 2 ||
        powerUp.position.y > canvas.height ||
        powerUp.position.y < 0 - powerUp.radius * 2
      ) {
        powerUps.splice(i, 1);
      } else {
        powerUp.update(context);
      }

      // Check for projectile collisions
      for (
        let projectileIndex = projectiles.length - 1;
        projectileIndex >= 0;
        projectileIndex--
      ) {
        const projectile = projectiles[projectileIndex];
        const dist = Math.hypot(
          projectile.x - powerUp.position.x,
          projectile.y - powerUp.position.y
        );

        // If projectile hits powerup
        if (dist - powerUp.radius - projectile.radius < 1) {
          // Create sparkle effect
          for (let i = 0; i < 8; i++) {
            particles.push(
              new Particle(
                projectile.x,
                projectile.y,
                Math.random() * 2,
                powerUp.color,
                {
                  x: (Math.random() - 0.5) * (Math.random() * 6),
                  y: (Math.random() - 0.5) * (Math.random() * 6),
                }
              )
            );
          }

          // Remove powerup and projectile
          powerUps.splice(i, 1);
          projectiles.splice(projectileIndex, 1);

          // Activate machine gun powerup
          player.powerUp = "MachineGun";
          player.color = powerUp.color;
          powerUpRef.current = powerUp.color; // Store powerUp color

          if (!audioMuted && audio.powerUp) {
            try {
              audio.powerUp.currentTime = 0;
              audio.powerUp.play();
            } catch (err) {
              console.log("PowerUp sound failed:", err);
            }
          }

          // Reset powerup after 10 seconds
          setTimeout(() => {
            player.powerUp = null;
            player.color = theme.textColorPrimary;
            powerUpRef.current = null; // Clear powerUp state
          }, 10000);

          break;
        }
      }
    }

    // Machine-Gun powerup shooting
    if (player.powerUp === "MachineGun") {
      const angle = Math.atan2(
        gameState.current.mouse.y - player.y,
        gameState.current.mouse.x - player.x
      );

      const velocity = {
        x: Math.cos(angle) * 5,
        y: Math.sin(angle) * 5,
      };

      if (gameState.current.frames % 4 === 0) {
        projectiles.push(
          new Projectile(player.x, player.y, 5, player.color, velocity)
        );

        if (!audioMuted && audio.shoot && gameState.current.frames % 8 === 0) {
          try {
            audio.shoot.currentTime = 0;
            audio.shoot.play();
          } catch (err) {
            console.log("Shoot sound failed:", err);
          }
        }
      }
    }

    // Update particles
    for (let index = particles.length - 1; index >= 0; index--) {
      const particle = particles[index];

      if (particle.alpha <= 0) {
        particles.splice(index, 1);
      } else {
        particle.update(context);
      }
    }

    // Draw projectiles
    projectiles.forEach((projectile, index) => {
      projectile.update(context);

      // Remove projectiles that are off screen
      if (
        projectile.x + projectile.radius < 0 ||
        projectile.x - projectile.radius > canvas.width ||
        projectile.y + projectile.radius < 0 ||
        projectile.y - projectile.radius > canvas.height
      ) {
        setTimeout(() => {
          projectiles.splice(index, 1);
        }, 0);
      }
    });

    // Update enemies and check collisions
    for (let index = enemies.length - 1; index >= 0; index--) {
      const enemy = enemies[index];
      enemy.update(context, player, canvas.width, canvas.height);

      // Check collision between player and enemy
      const dist = Math.hypot(player.x - enemy.x, player.y - enemy.y);
      if (dist - enemy.radius - player.radius < 1) {
        cancelAnimationFrame(gameState.current.animationId);
        clearInterval(gameState.current.intervalId);
        clearInterval(gameState.current.spawnPowerUpsId);
        audio.death.play();
        gameState.current.active = false;
        setGame({ active: false });
        setShowModal(true);

        gsap.fromTo(
          "#modalEl",
          {
            scale: 0.8,
            opacity: 0,
          },
          {
            scale: 1,
            opacity: 1,
            ease: "expo",
          }
        );
      }

      // Check collisions between projectiles and enemies
      for (
        let projectileIndex = projectiles.length - 1;
        projectileIndex >= 0;
        projectileIndex--
      ) {
        const projectile = projectiles[projectileIndex];
        const dist = Math.hypot(projectile.x - enemy.x, projectile.y - enemy.y);

        // Projectile hits enemy
        if (dist - enemy.radius - projectile.radius < 1) {
          // Create explosion particles
          for (let i = 0; i < enemy.radius * 2; i++) {
            particles.push(
              new Particle(
                projectile.x,
                projectile.y,
                Math.random() * 2,
                enemy.color,
                {
                  x: (Math.random() - 0.5) * (Math.random() * 6),
                  y: (Math.random() - 0.5) * (Math.random() * 6),
                }
              )
            );
          }

          // Shrink enemy or remove if too small
          if (enemy.radius - 10 > 5) {
            if (!audioMuted) {
              try {
                audio.damageTaken.currentTime = 0;
                audio.damageTaken
                  .play()
                  .catch((err) => console.log("Damage sound failed:", err));
              } catch (err) {
                console.log("Audio error:", err);
              }
            }
            setScore((prevScore) => prevScore + 100);
            gsap.to(enemy, { radius: enemy.radius - 10 });
            createScoreLabel({
              position: {
                x: projectile.x,
                y: projectile.y,
              },
              score: 100,
            });
            projectiles.splice(projectileIndex, 1);
          } else {
            // Remove enemy if too small
            if (!audioMuted) {
              try {
                audio.explosion.currentTime = 0;
                audio.explosion
                  .play()
                  .catch((err) => console.log("Explosion sound failed:", err));
              } catch (err) {
                console.log("Audio error:", err);
              }
            }
            setScore((prevScore) => prevScore + 150);
            createScoreLabel({
              position: {
                x: projectile.x,
                y: projectile.y,
              },
              score: 150,
            });

            // Change background particle colors
            backgroundParticles.forEach((backgroundParticle) => {
              gsap
                .timeline()
                .set(backgroundParticle, {
                  color: "white",
                  alpha: 0.1,
                })
                .to(backgroundParticle, {
                  color: enemy.color,
                  alpha: 0.1,
                  duration: 0.1,
                });

              backgroundParticle.color = enemy.color;
            });

            enemies.splice(index, 1);
            projectiles.splice(projectileIndex, 1);
          }
        }
      }
    }
  };

  useEffect(() => {
    // Preload sounds
    Object.values(audio).forEach((sound) => {
      sound.load();
    });
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Set initial canvas size
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Bind events
    const clickHandler = (e) => handleClick(e);
    canvas.addEventListener("click", clickHandler);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("resize", handleResize);

    const handleMouseDown = () => {
      gameState.current.isMouseDown = true;
    };

    const handleMouseUp = () => {
      gameState.current.isMouseDown = false;
    };

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("blur", () => {
      gameState.current.isMouseDown = false;
    });

    // Cleanup
    return () => {
      canvas.removeEventListener("click", clickHandler);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("resize", handleResize);
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("blur", () => {
        gameState.current.isMouseDown = false;
      });

      if (gameState.current.animationId) {
        cancelAnimationFrame(gameState.current.animationId);
      }
      if (gameState.current.intervalId) {
        clearInterval(gameState.current.intervalId);
      }
      if (gameState.current.spawnPowerUpsId) {
        clearInterval(gameState.current.spawnPowerUpsId);
      }

      // Stop all audio on unmount
      cleanupAudio();
    };
  }, []);

  // Add a separate effect for canvas resize
  useEffect(() => {
    const handleResize = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const parent = canvas.parentElement;
      canvas.width = parent.clientWidth;
      canvas.height = parent.clientHeight;
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const startGame = () => {
    const newGameState = { active: true };
    setGame(newGameState);

    // Force audio initialization on game start
    try {
      initializeAudio();

      // Play background music
      if (!audioMuted && audio.backgroundMusic) {
        try {
          audio.backgroundMusic.play();
        } catch (err) {
          console.log("Background music failed:", err);
        }
      }
    } catch (err) {
      console.log("Audio initialization error:", err);
    }

    init(newGameState);
    animate();
    spawnEnemies();
    spawnPowerUps();
    setShowStartModal(false);

    // Play select sound
    if (!audioMuted && audio?.select) {
      try {
        audio.select.play();
      } catch (err) {
        console.log("Select sound failed:", err);
      }
    }
  };

  const restartGame = () => {
    const newGameState = { active: true };
    setGame(newGameState);

    if (!audioMuted && audio.backgroundMusic) {
      try {
        audio.backgroundMusic.restart(); // Use new restart method
      } catch (err) {
        console.log("Background music restart failed:", err);
      }
    }

    init(newGameState);
    animate();
    spawnEnemies();
    spawnPowerUps();
    setShowModal(false);

    if (!audioMuted && audio?.select) {
      try {
        audio.select.play();
      } catch (err) {
        console.log("Select sound failed:", err);
      }
    }
  };

  const toggleAudio = () => {
    setAudioMuted((prevMuted) => {
      const newMuted = !prevMuted;

      try {
        // Just mute/unmute all sounds without stopping/starting
        Object.values(audio).forEach((sound) => {
          if (sound && typeof sound.mute === "function") {
            sound.mute(newMuted);
          }
        });
      } catch (err) {
        console.log("Audio toggle error:", err);
      }

      return newMuted;
    });
  };

  const Instructions = styled.p`
    margin: 1rem 0;

    @media (max-width: 768px) {
      &::before {
        content: "Tap to fire.";
      }
      &::after {
        display: none;
      }
    }

    @media (min-width: 769px) {
      &::before {
        content: "Use W, A, S, D to move. Click to fire.";
      }
    }
  `;

  return (
    <GameContainer>
      <canvas ref={canvasRef} />
      <Score>
        Score: <span>{score}</span>
      </Score>

      {/* Render floating score labels */}
      {scoreLabels.map((label) => (
        <FloatingScore
          key={label.id}
          position={label.position}
          value={label.score}
          onComplete={() =>
            setScoreLabels((prev) => prev.filter((l) => l.id !== label.id))
          }
        />
      ))}

      <VolumeControl onClick={toggleAudio}>
        {audioMuted ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
            />
          </svg>
        )}
      </VolumeControl>

      <Modal id="modalEl" show={showModal}>
        <h1>Game Over</h1>
        <h3>Score: {score}</h3>
        <StyledButton onClick={restartGame}>Restart Game</StyledButton>
      </Modal>

      <Modal id="startModalEl" show={showStartModal}>
        <h1>Blasteroid</h1>
        <h2>(aka: my blatant knock-off of Asteroid)</h2>
        <Instructions />
        <StyledButton onClick={startGame}>Start Game</StyledButton>
      </Modal>
    </GameContainer>
  );
};

export default Blasteroid;

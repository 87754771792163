import { queryClient } from "../store";
import * as SkillService from "../../services/SkillService";

const createSkillSlice = (set, get) => ({
  skills: [],

  setSkills: (skills) => set({ skills }),

  fetchSkills: async () => {
    const setLoading = get().useComponentLoading("data");
    setLoading(true);
    try {
      const skills = await queryClient.fetchQuery(
        "skills",
        SkillService.fetchSkills
      );
      set({ skills });
      return skills;
    } catch (error) {
      console.error("Error fetching skills:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  },
  refetchSkills: () => queryClient.invalidateQueries("skills"),
});

export default createSkillSlice;

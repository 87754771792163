// /SceneTransition/transitions/TransitionMaterial.js
import { shaderMaterial } from "@react-three/drei";
import { extend } from "@react-three/fiber";
import * as THREE from "three";

const TransitionMaterial = shaderMaterial(
  {
    uColor: new THREE.Color("white"),
    uProgression: 0,
    uResolution: new THREE.Vector2(),
    uTransitionType: 0,
  },
  /* glsl */ `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  /* glsl */ `
  uniform vec3 uColor;
  uniform float uProgression;
  uniform vec2 uResolution;
  uniform int uTransitionType;
  varying vec2 vUv;
  const float PI = 3.141592654;

  float getSpiralAlpha(vec2 uvs) {
    float r = length(uvs * 0.92);
    float theta = atan(uvs.y, uvs.x);
    float spiral = fract(2.5 * theta / PI + 7.0 * pow(r, 0.4) - 4.5 * uProgression);
    float animatedProgression = smoothstep(0.25, 1.0, uProgression);
    float alphaSpiral = step(animatedProgression, spiral);
    float animatedProgressionCircle = smoothstep(0.25, 0.8, uProgression);
    float alphaCircle = step(animatedProgressionCircle, r);
    float alpha = max(alphaSpiral, alphaCircle);
    float animatedProgressionOut = smoothstep(0.5, 1.0, uProgression);
    float alphaCircleOut = step(animatedProgressionOut, r);
    return min(alpha, alphaCircleOut);
  }

  // Early discard for fully transparent pixels
  float getAlpha(vec2 uvs) {
    if (uTransitionType != 4) {
      return 1.0 - uProgression;
    }

    vec2 centeredUvs = uvs - 0.5;
    centeredUvs.x *= uResolution.x / uResolution.y;
    return getSpiralAlpha(centeredUvs);
  }

  void main() {
    float alpha = getAlpha(vUv);
    if (alpha < 0.001) discard;
    gl_FragColor = vec4(uColor, alpha);
  }
`
);

extend({ TransitionMaterial });

import { queryClient } from "../store";
import * as ExperienceService from "../../services/ExperienceService";

const createExperienceSlice = (set, get) => ({
  experiences: [],
  setExperiences: (experiences) => set({ experiences }),

  fetchExperiences: async () => {
    const setLoading = get().useComponentLoading("data");
    setLoading(true);
    try {
      const experiences = await queryClient.fetchQuery(
        "experiences",
        ExperienceService.fetchExperiences
      );
      set({ experiences });
      return experiences;
    } catch (error) {
      console.error("Error fetching experiences:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  },
  refetchExperiences: () => queryClient.invalidateQueries("experiences"),
});

export default createExperienceSlice;

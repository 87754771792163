// components/SceneTransition/SceneTransition.jsx
import { Hud, OrthographicCamera } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import React, { useEffect, useRef, useMemo } from "react";
import * as THREE from "three";
import "./transitions/TransitionMaterial";
import useStore from "../../stores/store";
import { getExperience } from "../Experience/ExperienceSystem/ExperienceSystem";

function SceneTransition({ transition, color }) {
  const materialRef = useRef();
  const { size } = useThree();
  const currentScene = useStore((state) => state.currentScene);
  const experience = getExperience(currentScene);
  const transitionType = experience?.transition?.type ?? 0;
  const transitionSpeed = experience?.transition?.speed ?? 0.1;
  const setScene = useStore((state) => state.setScene);
  const targetScene = useStore((state) => state.targetScene);
  const completeTransition = useStore((state) => state.completeTransition);

  // Memoize geometry to prevent recreations
  const geometry = useMemo(() => new THREE.PlaneGeometry(2, 2), []);

  // Memoize resolution vector to prevent unnecessary updates
  const resolution = useMemo(
    () => new THREE.Vector2(size.width, size.height),
    [size.width, size.height]
  );

  // Skip rendering when transition is complete
  const shouldRender = useRef(true);

  useFrame((state, delta) => {
    if (!materialRef.current || !shouldRender.current) return;

    const currentProgress = materialRef.current.uProgression;
    const targetValue = transition ? 0 : 1;

    // Calculate new progression value
    const newProgress = THREE.MathUtils.lerp(
      currentProgress,
      targetValue,
      transitionSpeed
    );

    materialRef.current.uProgression = newProgress;

    // Handle transition completion
    if (transition && Math.abs(newProgress - 1) < 0.01 && targetScene) {
      setScene(targetScene);
    } else if (!transition && Math.abs(newProgress) < 0.01) {
      completeTransition();
      shouldRender.current = false; // Disable rendering when not transitioning
    }

    materialRef.current.uResolution = resolution;
  });

  // Reset render flag when transition starts
  useEffect(() => {
    if (transition) {
      shouldRender.current = true;
    }
  }, [transition]);

  // Don't render if there's no active transition and progress is complete
  if (!shouldRender.current && !transition) return null;

  return (
    <Hud renderPriority={1}>
      <OrthographicCamera
        makeDefault
        top={1}
        right={1}
        bottom={-1}
        left={-1}
        near={0}
        far={1}
      />
      <mesh frustumCulled={false}>
        <primitive object={geometry} />
        <transitionMaterial
          ref={materialRef}
          uColor={color}
          transparent
          depthTest={false}
          depthWrite={false}
          uProgression={0}
          uTransitionType={transitionType}
          uResolution={resolution}
        />
      </mesh>
    </Hud>
  );
}

export default React.memo(SceneTransition);

// stores/store.js

import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { QueryClient } from "react-query";

// Import state slices
import createFactSlice from "./slices/factSlice";
import createSkillSlice from "./slices/skillSlice";
import createTechnologySlice from "./slices/technologySlice";
import createExperienceSlice from "./slices/experiencesSlice";
import createProjectSlice from "./slices/projectSlice";
import createThemeSlice from "./slices/themeSlice";
import createViewportSlice from "./slices/viewportSlice";
import createSlideOutSlice from "./slices/slideOutSlice";
import createSceneTransitionSlice from "./slices/sceneTransitionSlice";
import createArticleSlice from "./slices/articleSlice";
import createImageSlice from "./slices/imageSlice";
import createCaseStudySlice from "./slices/caseStudySlice";
import createLoadingSlice from "./slices/loadingSlice";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 60 * 1000,
    },
  },
});

const initialState = {
  isInitialized: false,
  isInitializing: false,
  initError: null,
  initialized: {
    theme: false,
    viewport: false,
    experiences: false,
  },
  currentScene: "DODECAHEDRON",
};

const initializeApp = async (state) => {
  if (!state) throw new Error("State is undefined during initialization");

  // Initialize scene first
  if (typeof state.initializeScene === "function") {
    const initialScene = state.initializeScene();
    if (initialScene && typeof state.setScene === "function") {
      state.setScene(initialScene);
    }
  }

  const initPromises = [];

  if (typeof state.initTheme === "function") {
    initPromises.push(state.initTheme());
  }
  if (typeof state.initViewport === "function") {
    initPromises.push(state.initViewport());
  }
  if (typeof state.initExperiences === "function") {
    initPromises.push(state.initExperiences());
  }

  try {
    const results = await Promise.all(initPromises);
    return true;
  } catch (error) {
    console.error("Initialization failed:", error);
    throw error;
  }
};

const createStore = (set, get) => {
  // Create store with default state
  const store = {
    ...initialState,

    initialize: async () => {
      const state = get();

      if (state.isInitialized || state.isInitializing) {
        console.log("Already initialized or initializing");
        return;
      }

      set({ isInitializing: true, initError: null, isLoading: true });

      try {
        const result = await initializeApp(get());
        if (!result) throw new Error("Initialization failed");

        set({
          isInitialized: true,
          isInitializing: false,
          initialized: {
            theme: true,
            viewport: true,
            experiences: true,
          },
          isLoading: false,
        });
      } catch (error) {
        console.error("Store initialization failed:", error);
        set({
          initError: error.message,
          isInitializing: false,
          isInitialized: false,
          isLoading: false,
        });
        throw error;
      }
    },

    resetInitialization: () => {
      set({ ...initialState });
    },

    getInitializationStatus: () => {
      const state = get();
      return {
        isInitialized: state.isInitialized,
        isInitializing: state.isInitializing,
        initError: state.initError,
        initialized: state.initialized,
      };
    },
  };

  // Combine all slices
  return {
    ...store,
    ...createArticleSlice(set, get),
    ...createFactSlice(set, get),
    ...createImageSlice(set, get),
    ...createSkillSlice(set, get),
    ...createTechnologySlice(set, get),
    ...createExperienceSlice(set, get),
    ...createProjectSlice(set, get),
    ...createCaseStudySlice(set, get),
    ...createThemeSlice(set, get),
    ...createViewportSlice(set, get),
    ...createSlideOutSlice(set, get),
    ...createSceneTransitionSlice(set, get),
    ...createLoadingSlice(set, get),
  };
};

const useStore = create(
  devtools(
    persist(createStore, {
      name: "root-storage",
      partialize: (state) => ({
        theme: state.theme,
        themeName: state.themeName,
        currentScene: state.currentScene,
        isInitialized: state.isInitialized,
        initialized: state.initialized,
      }),
      onRehydrateStorage: () => (state) => {
        console.log("Storage rehydrated successfully:", state);
      },
    })
  )
);

export default useStore;

// pages/Home

import React, { useMemo } from "react";
import useStore from "../stores/store";
import HomeDashboard from "../components/Dashboards/HomeDashboard";

function Home() {
  const { theme } = useStore();

  return <HomeDashboard />;
}

export default Home;

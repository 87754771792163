import { queryClient } from "../store";
import * as ProjectService from "../../services/ProjectService";

const createProjectSlice = (set, get) => ({
  projects: [],

  setProjects: (projects) => set({ projects }),

  fetchProjects: async () => {
    const setLoading = get().useComponentLoading("data");
    setLoading(true);
    try {
      const projects = await queryClient.fetchQuery(
        "projects",
        ProjectService.fetchProjects
      );
      set({ projects });
      return projects;
    } catch (error) {
      console.error("Error fetching projects:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  },

  refetchProjects: () => queryClient.invalidateQueries("projects"),

  fetchProjectsByStatus: async (status) => {
    const setLoading = get().useComponentLoading("data");
    setLoading(true);
    try {
      const projects = await queryClient.fetchQuery(["projects", status], () =>
        ProjectService.fetchProjectsByStatus(status)
      );
      return projects;
    } catch (error) {
      console.error(`Error fetching projects with status ${status}:`, error);
      throw error;
    } finally {
      setLoading(false);
    }
  },

  fetchProjectsByCategory: async (category) => {
    return queryClient.fetchQuery(["projects", category], () =>
      ProjectService.fetchProjectsByCategory(category)
    );
  },

  fetchFeaturedProjects: async () => {
    return queryClient.fetchQuery(
      "featuredProjects",
      ProjectService.fetchFeaturedProjects
    );
  },
});

export default createProjectSlice;

// stores/slices/technologySlice.js

import { queryClient } from "../store";
import * as TechnologyService from "../../services/TechnologyService";

const createTechnologySlice = (set, get) => ({
  technologies: [],

  setTechnologies: (technologies) => set({ technologies }),

  fetchTechnologies: async () => {
    const setLoading = get().useComponentLoading("data");
    setLoading(true);
    try {
      const technologies = await queryClient.fetchQuery(
        "technologies",
        TechnologyService.fetchTechnologies
      );
      set({ technologies });
      return technologies;
    } catch (error) {
      console.error("Error fetching technologies:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  },
  refetchTechnologies: () => queryClient.invalidateQueries("technologies"),
});

export default createTechnologySlice;

import { TRANSITION_TIMING, TransitionType } from "../../constants/transitions";
import { getExperienceForScene } from "../../constants/sceneExperiences";
import { getExperience } from "../../components/Experience/ExperienceSystem/ExperienceSystem";

// Animation Manager singleton for handling all animations
const AnimationManager = {
  activeAnimations: new Map(),
  frameId: null,

  update(timestamp) {
    this.frameId = requestAnimationFrame(this.update.bind(this));

    for (const [id, animation] of this.activeAnimations) {
      if (animation.update(timestamp)) {
        this.activeAnimations.delete(id);
      }
    }

    if (this.activeAnimations.size === 0) {
      cancelAnimationFrame(this.frameId);
      this.frameId = null;
    }
  },

  startAnimation(id, animation) {
    this.activeAnimations.set(id, animation);
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.update.bind(this));
    }
  },

  stopAnimation(id) {
    this.activeAnimations.delete(id);
  },
};

// Transition Animation class
class TransitionAnimation {
  constructor(duration, updateFn, completeFn) {
    this.startTime = null;
    this.duration = duration;
    this.updateFn = updateFn;
    this.completeFn = completeFn;
  }

  update(timestamp) {
    if (!this.startTime) this.startTime = timestamp;

    const elapsed = timestamp - this.startTime;
    const progress = Math.min(elapsed / this.duration, 1);

    this.updateFn(progress);

    if (progress === 1) {
      this.completeFn?.();
      return true;
    }
    return false;
  }
}

const initialState = {
  currentScene: null,
  targetScene: null,
  isTransitioning: true,
  transitionProgress: 1,
  transitionType: "FADE",
  transitionError: null,
  previousScene: null,
  isLoading: true,
  isInitialLoad: true,
  transitionPhase: "none", // 'out', 'switching', 'in', or 'none'
};

const createSceneTransitionSlice = (set, get) => {
  const batchedSet = (updates) => {
    const batchedUpdates = { ...get(), ...updates };
    set(batchedUpdates);
  };

  return {
    ...initialState,

    setLoading: (loading) => {
      batchedSet({ isLoading: loading });
    },

    setScene: (sceneName) => {
      if (!sceneName) return;

      const state = get();
      const normalizedNewScene = sceneName.toLowerCase();
      const normalizedCurrentScene = state.currentScene?.toLowerCase();

      if (normalizedNewScene === normalizedCurrentScene) {
        batchedSet({ isLoading: false });
        return;
      }

      if (state.transitionPhase === "none") {
        batchedSet({
          currentScene: sceneName,
          isTransitioning: false,
          transitionProgress: 0,
          isLoading: false,
        });
        return;
      }

      if (state.transitionPhase === "switching") {
        batchedSet({
          currentScene: sceneName,
          transitionPhase: "in",
        });
      }
    },

    initializeScene: () => {
      const path = window.location.pathname.slice(1) || "/";
      const experience = getExperience(null, path);
      const initialScene = experience?.key?.toUpperCase() || "DODECAHEDRON";

      batchedSet({
        currentScene: initialScene,
        isTransitioning: true,
        transitionProgress: 1,
        isLoading: true,
        isInitialLoad: true,
        transitionPhase: "none",
      });

      return initialScene;
    },

    completeInitialLoad: () => {
      const fadeIn = new TransitionAnimation(
        TRANSITION_TIMING.DURATION,
        (progress) => {
          batchedSet({
            transitionProgress: 1 - progress,
          });
        },
        () => {
          batchedSet({
            isTransitioning: false,
            transitionProgress: 0,
            isLoading: false,
            isInitialLoad: false,
            transitionPhase: "none",
          });
        }
      );

      AnimationManager.startAnimation("initialFade", fadeIn);
    },

    startTransition: async (nextScene) => {
      const state = get();

      if (
        !nextScene ||
        nextScene.toLowerCase() === state.currentScene?.toLowerCase()
      ) {
        batchedSet({ isLoading: false });
        return;
      }

      batchedSet({
        isTransitioning: true,
        targetScene: nextScene,
        previousScene: state.currentScene,
        transitionProgress: 0,
        transitionError: null,
        isLoading: true,
        transitionPhase: "out",
      });

      const fadeOutAnimation = new TransitionAnimation(
        TRANSITION_TIMING.DURATION,
        (progress) => {
          batchedSet({ transitionProgress: progress });
        },
        () => {
          batchedSet({
            transitionPhase: "switching",
            currentScene: nextScene,
            transitionPhase: "in",
          });
        }
      );

      AnimationManager.startAnimation("transitionOut", fadeOutAnimation);
    },

    completeTransition: () => {
      const state = get();

      if (state.isInitialLoad) {
        return;
      }

      if (state.transitionPhase !== "in") {
        return;
      }

      const fadeInAnimation = new TransitionAnimation(
        TRANSITION_TIMING.DURATION,
        (progress) => {
          batchedSet({
            transitionProgress: 1 - progress,
          });
        },
        () => {
          batchedSet({
            targetScene: null,
            isTransitioning: false,
            transitionProgress: 0,
            isLoading: false,
            transitionPhase: "none",
          });
        }
      );

      AnimationManager.startAnimation("transitionComplete", fadeInAnimation);
    },

    cancelTransition: () => {
      AnimationManager.stopAnimation("transitionOut");
      AnimationManager.stopAnimation("transitionComplete");

      batchedSet({
        ...initialState,
        currentScene: get().previousScene || get().currentScene,
        isLoading: false,
        isInitialLoad: false,
        transitionPhase: "none",
      });
    },

    setTransitionError: (error) => {
      batchedSet({
        transitionError: error,
        isTransitioning: false,
        targetScene: null,
        transitionProgress: 0,
        isLoading: false,
        isInitialLoad: false,
        transitionPhase: "none",
      });
    },

    getTransitionValues: () => {
      const state = get();
      const progress = state.transitionProgress;

      const currentExperience = getExperienceForScene(state.currentScene);
      const targetExperience = state.targetScene
        ? getExperienceForScene(state.targetScene)
        : null;

      if (state.isTransitioning && targetExperience?.transition?.interpolate) {
        const customValues = targetExperience.transition.interpolate(progress);
        return {
          opacity: customValues.opacity ?? 1,
          scale: customValues.scale ?? 1,
          position: customValues.position ?? [0, 0, 0],
        };
      }

      switch (state.transitionType) {
        case TransitionType.FADE:
          return {
            opacity: state.isTransitioning ? 1 - progress : 1,
            scale: 1,
            position: [0, 0, 0],
          };

        case TransitionType.SCALE:
          return {
            opacity: 1,
            scale: state.isTransitioning ? 1 - progress * 0.5 : 1,
            position: [0, 0, 0],
          };

        case TransitionType.SLIDE_LEFT:
          return {
            opacity: 1,
            scale: 1,
            position: [state.isTransitioning ? -progress * 100 : 0, 0, 0],
          };

        case TransitionType.SLIDE_RIGHT:
          return {
            opacity: 1,
            scale: 1,
            position: [state.isTransitioning ? progress * 100 : 0, 0, 0],
          };

        case TransitionType.SLIDE_UP:
          return {
            opacity: 1,
            scale: 1,
            position: [0, state.isTransitioning ? progress * 100 : 0, 0],
          };

        case TransitionType.SLIDE_DOWN:
          return {
            opacity: 1,
            scale: 1,
            position: [0, state.isTransitioning ? -progress * 100 : 0, 0],
          };

        case TransitionType.ZOOM_IN:
          return {
            opacity: 1,
            scale: state.isTransitioning ? 1 + progress : 1,
            position: [0, 0, 0],
          };

        case TransitionType.ZOOM_OUT:
          return {
            opacity: 1,
            scale: state.isTransitioning ? 1 - progress * 0.5 : 1,
            position: [0, 0, 0],
          };

        default:
          return {
            opacity: 1,
            scale: 1,
            position: [0, 0, 0],
          };
      }
    },

    getTransitionState: () => {
      const state = get();
      return {
        isTransitioning: state.isTransitioning,
        currentScene: state.currentScene,
        targetScene: state.targetScene,
        progress: state.transitionProgress,
        type: state.transitionType,
        error: state.transitionError,
        isLoading: state.isLoading,
        isInitialLoad: state.isInitialLoad,
        phase: state.transitionPhase,
      };
    },

    resetTransition: () => {
      AnimationManager.stopAnimation("initialFade");
      AnimationManager.stopAnimation("transitionOut");
      AnimationManager.stopAnimation("transitionComplete");

      batchedSet({
        ...initialState,
        isInitialLoad: false,
      });
    },
  };
};

export default createSceneTransitionSlice;

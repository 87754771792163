import { queryClient } from "../store";
import * as FactService from "../../services/FactService";

const createFactSlice = (set, get) => ({
  facts: [],

  setFacts: (facts) => set({ facts }),

  fetchFacts: async () => {
    const setLoading = get().useComponentLoading("data");
    setLoading(true);
    try {
      const facts = await queryClient.fetchQuery(
        "facts",
        FactService.fetchFacts
      );
      set({ facts });
      return facts;
    } catch (error) {
      console.error("Error fetching facts:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  },

  refetchFacts: () => queryClient.invalidateQueries("facts"),
});

export default createFactSlice;

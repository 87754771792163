// stores/slices/createArticleSlice.js

import { queryClient } from "../store";
import * as ArticleService from "../../services/ArticleService";

const createArticleSlice = (set, get) => ({
  articles: [],

  setArticles: (articles) => set({ articles }),

  fetchArticles: async () => {
    const setLoading = get().useComponentLoading("data");
    setLoading(true);
    try {
      const articles = await queryClient.fetchQuery(
        "articles",
        ArticleService.fetchArticles
      );
      set({ articles });
      return articles;
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  },

  refetchArticles: () => queryClient.invalidateQueries("articles"),
});

export default createArticleSlice;

import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import "./stylesGameDev.scss";
import useStore from "../stores/store";
import {
  BentoBox,
  BentoItem,
  BentoWrapper,
} from "../components/BentoBox/BentoBox";
import breakpoints, { media } from "../styles/breakpoints";
import styled from "styled-components";
import {
  Wrapper,
  Content,
  ScrollContent,
  ContentWrapper,
  StyledImage,
} from "../components/BentoBox/StyledComponents";
import Scrollbar from "../components/Scrollbar/Scrollbar";
import Hero from "../components/BentoBox/Hero";
import { useViewport } from "../context/ViewportContext";
import { preloadBackgroundImages, preloadImages } from "../utils/preloadImages";
import SplitContent from "../experiences/Photography/Imprisoned/SplitContent";

const Title = styled.h1`
  && {
    font-size: calc(4.5 * (1vh + 1vw));
    line-height: calc(3.25 * (1vh + 1vw));
    text-align: right;

    ${media.between("laptopL", "desktop")`
      font-size: calc(5.25 * (1vh + 1vw));
      line-height: calc(3.75 * (1vh + 1vw));
    `}

    ${media.between("laptopS", "laptopL")`
      font-size: clamp(2.5rem, 5vw + 1rem, 5.5rem);
      line-height: clamp(1.5rem, 3vw + 1rem, 3.5rem);
    `}

    ${media.between("tabletL", "laptopS")`
      font-size: calc(3.75 * (1vh + 1vw));
      line-height: calc(3 * (1vh + 1vw));
    `}

    ${media.between("tabletM", "tabletL")`
      font-size: clamp(2.5rem, 5vw + 1rem, 5.5rem);
      line-height: clamp(1.5rem, 3vw + 1rem, 3.5rem);
    `}

    ${media.between("tabletS", "tabletM")`
      font-size: clamp(2.5rem, 10vw + 1rem, 10.5rem);
      line-height: clamp(3.25rem, 3vw + 1rem, 5.5rem);
    `}


    ${media.down("tabletS")`
      font-size: calc(4.25 * (1vh + 1vw));
      line-height: calc(3.75 * (1vh + 1vw));
      margin-bottom: 0;
    `}
  }
`;

const GridLayout = styled.div`
  display: grid;
  gap: 1rem;
  width: 100%;
  grid-column: 1/-1;
  grid-row: auto;

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    margin-top: 0;
  `}
`;

const Grid5x1 = styled(GridLayout)`
  grid-template-columns: repeat(5, 1fr);

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}
`;

const Grid3x2 = styled(GridLayout)`
  grid-template-columns: repeat(3, 1fr);

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}
`;

const SplitLayout = styled.div`
  display: grid;
  gap: 1rem;
  grid-column: 1/-1;

  ${media.down("tabletM")`
    grid-template-columns: 1fr;
  `}
`;

const SplitMainAndSidebar = styled(SplitLayout)`
  grid-template-columns: ${(props) =>
    props.reverseOrder ? "1fr 1fr" : "1fr 1fr"};
  direction: ${(props) => (props.reverseOrder ? "rtl" : "ltr")};

  > * {
    direction: ltr; // Reset direction for children
  }

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: 1 / -1;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    direction: ltr;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    direction: ltr;
  `}
`;

const Grid1x1 = styled(GridLayout)`
  grid-template-columns: 1fr;
  grid-column: 1;

  ${media.down("tabletS")`
    grid-template-columns: 1fr;
  `}
`;

const Grid2x2 = styled(GridLayout)`
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1/-1;

  ${media.up("desktop")`
  `}

  ${media.between("laptopS", "desktop")`
  `}

  ${media.between("tabletM", "laptopS")`
    grid-template-columns: 1fr;
    grid-column: 1/-1;
    margin-right: 0.5rem;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/-1;
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    grid-template-columns: 1fr;
  `}
`;

const Grid3x3 = styled(GridLayout)`
  grid-template-columns: repeat(3, 1fr);
  grid-column: 1;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
  `}
`;

const FullWidthItem = styled(BentoItem)`
  grid-column: 1/-1;
  height: 90%;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: 1/-1;
    margin-right: 0.5rem;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2/-1;
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
  `}
`;

const GridItem = styled(BentoItem)`
  height: 100%;

  ${media.between("mobileL", "tabletL")`
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    margin-right: 0.5rem;
  `}
`;

const MainContent = styled(BentoItem)`
  height: 100%;
  align-content: center;
  justify-self: center;
  width: 80%;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    margin-right: 0.5rem;
    width: 95%;
  `}

  ${media.down("mobileL")`
    margin-right: 0.5rem;
    width: 95%;
  `}
`;

const ImageItem = styled(BentoItem)`
  height: 100%;

  ${media.down("tabletS")`
    margin-right: 0.5rem;
  `}
`;

function GameDev() {
  const [isLoading, setIsLoading] = useState(true);
  const viewport = useViewport();
  const { width, height } = viewport;
  const [orientationKey, setOrientationKey] = useState(0);
  const [shouldShowScrollbar, setShouldShowScrollbar] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(viewport.isLandscape);
  const { theme } = useStore();

  const { images, fetchImages } = useStore();

  const contentKey = useMemo(() => {
    return `portrait-${viewport.width}-${viewport.height}-${isLandscape}`;
  }, [viewport.width, viewport.height, isLandscape]);

  const updateDimensions = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const contentHeight = contentRef.current.scrollHeight;
      setShouldShowScrollbar(contentHeight > containerHeight);
    }
  }, []);

  useEffect(() => {
    const loadImagesAndPreload = async () => {
      try {
        await fetchImages("photography", "imprisoned");
        await Promise.all([
          preloadImages(".grid__img"),
          preloadBackgroundImages(".grid__img"),
        ]);
        setIsLoading(false);
        window.scrollTo(0, 0);
        updateDimensions();
      } catch (error) {
        console.error("Error loading images:", error);
        setIsLoading(false);
      }
    };

    loadImagesAndPreload();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [fetchImages, updateDimensions]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientationKey((prev) => prev + 1);
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    updateDimensions();
  }, [viewport, isLandscape, updateDimensions]);

  const leftMargin = useMemo(() => {
    if (!viewport) return "5.25rem";
    const { width } = viewport;

    if (width < breakpoints.mobileL) return "3rem";
    if (width < breakpoints.tabletL) return "3.5rem";
    if (width < breakpoints.laptopS) return "5.25rem";
    if (width < breakpoints.desktop) return "5.25rem";
    return "5.25rem";
  }, [viewport]);

  const gridImages = images.map((image) => image.file);

  return (
    <Wrapper>
      <Content>
        <Scrollbar
          scrollbarPosition="left"
          theme={theme}
          applyScrolling={true}
          forceScrollbar={true}
          trackMargin={{
            top: "0",
            bottom: "0",
            left: leftMargin,
            right: "0",
          }}
        >
          <ScrollContent>
            <ContentWrapper>
              <BentoBox>
                <Hero>
                  <Title>GameDev: Yr.2</Title>
                </Hero>
                <SplitContent headline="Prologue:" image={gridImages[0]}>
                  <p>
                    Full disclosure, I never set out to be a developer. In fact,
                    I've actively avoided the idea for over 20 years. Sure, I've
                    been working with websites since 1996 thanks to full-time
                    gigs at agencies, etc., but, here's the thing: I was always
                    a marketer first, not an engineer.
                  </p>
                  <p>
                    Specifically, I've always been on the strategy and visuals
                    side of the industry, with just enough coding skills to be
                    dangerous.
                  </p>
                  <p>
                    In your average marketing agency, most folks leverage things
                    like WordPress templates and page builders, tweaking from a
                    preexisting foundation. It works but, it always left me
                    feeling vulnerable.
                  </p>
                  <p>
                    Yet, even when I built Wabbit, that vulnerability was an
                    afterthought. I'd just outsource the stuff I couldn't
                    handle, and I'd built a fantastic remote team in Ukraine.
                  </p>
                  <p>And then I lost contact with them.</p>
                  <p>That was the November before the invasion.</p>
                </SplitContent>
                <FullWidthItem>
                  <StyledImage src={gridImages[1]} />
                </FullWidthItem>
                <SplitContent
                  headline="The Breaking Point"
                  image={gridImages[0]}
                >
                  <p>
                    I try not to dwell on those early months of the war. The
                    professional crisis paled against the human one. But as I
                    worried about my team's safety, Wabbit faced an existential
                    threat: we'd built our entire technical foundation on
                    outsourced talent.{" "}
                  </p>
                  <p>Now that foundation was gone.</p>
                </SplitContent>
                <SplitContent
                  headline="My Past Failures"
                  image={gridImages[0]}
                  imageOnLeft={false}
                >
                  <p>
                    I'd tried learning programming before. Multiple times,
                    actually. But tutorials focused on building to-do lists and
                    accounting systems never held my attention. Each attempt
                    ended in frustration and abandonment. I needed something
                    different - something that would keep me engaged through the
                    difficult fundamentals.
                  </p>
                </SplitContent>
                <SplitContent
                  headline="An Unexpected Solution"
                  image={gridImages[0]}
                >
                  <p>
                    A few months before losing contact with my team, I'd started
                    exploring game development and virtual production through
                    tutorials. Not as a career move - just evening experiments
                    with Unreal Engine. A creative escape from business
                    pressures.
                  </p>
                  <p>
                    What followed could have been another failed attempt at
                    learning to code. Instead, it became my salvation.
                  </p>
                  <p>
                    Game development demanded real programming knowledge - no
                    templates or workarounds would suffice. But unlike previous
                    attempts at learning to code, applying the lessons to games
                    held my interest. Instead of managing data in abstract
                    exercises, I was building worlds and creating interactions
                    that excited me.
                  </p>
                </SplitContent>
                <SplitContent
                  headline="Year 1: Foundation"
                  image={gridImages[0]}
                  imageOnLeft={false}
                >
                  <p>
                    Throughout 2023, I built my technical skills from the ground
                    up. I learned Unreal Engine from Stephen Ulibarri's Udemy
                    courses, and Harvard's CS50 became my guide through computer
                    science fundamentals. I earned two certificates there, but
                    more importantly, I finally began understanding how software
                    really works.
                  </p>
                  <p>
                    Still, I had large gaps in my understanding. Many computer
                    science concepts still remained frustratingly abstract.
                    Adding game development into the mix only overcomplicated
                    things. I needed faster, bigger wins.
                  </p>
                  <p>I needed to leverage something I already understood...</p>
                </SplitContent>
                <SplitContent
                  headline="Year 2: Breakthrough"
                  image={gridImages[0]}
                >
                  <p>
                    2024 brought the transformation. You're experiencing the
                    results right now.
                  </p>
                  <p>
                    First, I updated my laughable HTML and CSS skills to modern
                    standards, then began to tackle increasingly complex
                    programming challenges. JavaScript, Python, Django, React,
                    ThreeJS - tools that once seemed impossibly complex became
                    daily companions.
                  </p>
                  <p></p>
                  <p>
                    While far from perfect, and clearly not gamified yet, this
                    site represents tangible proof of the journey to date.
                  </p>
                </SplitContent>
                <SplitContent
                  headline="Looking Forward"
                  imageOnLeft={false}
                  image={gridImages[0]}
                >
                  <p>In 2025, I'm shifting from learning to creating.</p>
                  <p>
                    Yes, there's still a long way to go but, the fundamentals
                    are in place now. The technical barriers have fallen. It's
                    time to build the experiences I've been dreaming about.
                  </p>
                  <p>
                    As you've seen, I have renders, virtual production tests,
                    gameplay prototypes, and browser-based assets in various
                    stages of completion.
                  </p>
                  <p>None of it is cohesive. That will change.</p>
                  <p>
                    By the end of 2025, there will be a variety of experiences
                    and games here. Watch this space for playable demos, etc.
                  </p>
                </SplitContent>
              </BentoBox>
            </ContentWrapper>
          </ScrollContent>
        </Scrollbar>
      </Content>
    </Wrapper>
  );
}

export default GameDev;

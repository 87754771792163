// components/CaseStudy/CaseStudy.jsx

import * as THREE from "three";
import React, {
  Suspense,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { Canvas, useThree, useFrame, useLoader } from "@react-three/fiber";
import { Flex, Box, useFlexSize } from "@react-three/flex";
import {
  Loader,
  Line,
  useAspect,
  Text,
  OrbitControls,
} from "@react-three/drei";
import { EffectComposer } from "@react-three/postprocessing";
import useStore from "../../stores/store";

const state = {
  top: 0,
  pages: 0,
  threshold: 4,
  mouse: [0, 0],
  content: [
    { tag: "Mission", text: "" },
    { tag: "Scope", text: "" },
    { tag: "Challenges", text: "" },
    { tag: "Outcomes", text: "" },
    { tag: "Impacts", text: "" },
    { tag: "Backstory", text: "" },
    { tag: "Goal", text: "" },
    { tag: "Gap", text: "" },
    { tag: "Gamble", text: "" },
    { tag: "Approach", text: "" },
    { tag: "Phases", text: "" },
    { tag: "Closing Thoughts", text: "" },
  ],
  lines: [
    {
      points: [
        [-20, 0, 0],
        [-9, 0, 0],
      ],
      color: "black",
      lineWidth: 0.5,
    },
    {
      points: [
        [20, 0, 0],
        [9, 0, 0],
      ],
      color: "black",
      lineWidth: 0.5,
    },
  ],
};

function HeightReporter({ onReflow }) {
  const size = useFlexSize();
  useEffect(() => onReflow && onReflow(...size), [onReflow, size]);
  return null;
}

function Page({ tag, text, image, textScaleFactor, onReflow, left = false }) {
  const { viewport } = useThree();
  const texture = useLoader(THREE.TextureLoader, image);
  return (
    <Box
      dir="column"
      align={left ? "flex-start" : "flex-end"}
      justify="flex-start"
      width="100%"
      height="auto"
      minHeight="100%"
    >
      <HeightReporter onReflow={onReflow} />
      <Box
        dir="row"
        width="100%"
        height="auto"
        justify={left ? "flex-end" : "flex-start"}
        margin={0}
        grow={1}
      >
        <Box width={6} height={4}>
          <mesh>
            <planeGeometry args={[6, 4]} />
            <meshBasicMaterial map={texture} toneMapped={false} />
          </mesh>
        </Box>
      </Box>
      <Box marginLeft={1.5} marginRight={1.5} marginTop={2}>
        <Text
          position={[left ? 1 : -1, 0.5, 1]}
          fontSize={textScaleFactor}
          lineHeight={1}
          letterSpacing={-0.05}
          maxWidth={(viewport.width / 4) * 3}
        >
          {tag}
          <meshBasicMaterial color="#cccccc" toneMapped={false} />
        </Text>
      </Box>
      <Box
        marginLeft={left ? 1.5 : 1}
        marginRight={left ? 1 : 1.5}
        marginBottom={1}
      >
        <Text
          bold
          position-z={0.5}
          textAlign={left ? "left" : "right"}
          fontSize={1.5 * textScaleFactor}
          lineHeight={1}
          letterSpacing={-0.05}
          color="black"
          maxWidth={(viewport.width / 4) * 3}
        >
          {text}
        </Text>
      </Box>
    </Box>
  );
}

function Layercard({
  depth,
  boxWidth,
  boxHeight,
  text,
  textColor,
  color,
  map,
  textScaleFactor,
}) {
  const ref = useRef();
  const { viewport, size } = useThree();
  const pageLerp = useRef(state.top / size.height);
  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(
      pageLerp.current,
      state.top / size.height,
      0.15
    ));
    if (depth >= 0)
      ref.current.opacity =
        page < state.threshold * 1.7 ? 1 : 1 - (page - state.threshold * 1.7);
  });
  return (
    <>
      <mesh position={[boxWidth / 2, -boxHeight / 2, depth]}>
        <planeGeometry args={[boxWidth, boxHeight]} />
        <meshBasicMaterial
          ref={ref}
          color={color}
          map={map}
          toneMapped={false}
          transparent
          opacity={1}
        />
      </mesh>
      <Text
        bold
        position={[boxWidth / 2, -boxHeight / 2, depth + 1.5]}
        maxWidth={(viewport.width / 4) * 1}
        anchorX="center"
        anchorY="middle"
        fontSize={0.6 * textScaleFactor}
        lineHeight={1}
        letterSpacing={-0.05}
        color={textColor}
      >
        {text}
      </Text>
    </>
  );
}

function Content({ onReflow, caseStudy }) {
  const group = useRef();
  const { viewport, size } = useThree();
  const [bW, bH] = useAspect(1920, 1920, 0.5);
  const texture = useLoader(THREE.TextureLoader, caseStudy.thumbnail_img);
  const vec = new THREE.Vector3();
  const pageLerp = useRef(state.top / size.height);
  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(
      pageLerp.current,
      state.top / size.height,
      0.15
    ));
    const y = page * viewport.height;
    const sticky = state.threshold * viewport.height;
    group.current.position.lerp(
      vec.set(
        0,
        page < state.threshold ? y : sticky,
        page < state.threshold ? 0 : page * 1.25
      ),
      0.15
    );
  });
  const handleReflow = useCallback(
    (w, h) => onReflow((state.pages = h / viewport.height + 5.5)),
    [onReflow, viewport.height]
  );
  const sizesRef = useRef([]);
  const scale = Math.min(1, viewport.width / 16);

  // Update state.content with case study data
  state.content[0].text = caseStudy.mission;
  state.content[1].text = caseStudy.scope.map((item) => item.item).join(", ");
  state.content[2].text = caseStudy.challenges
    .map((challenge) => challenge.description)
    .join("\n\n");
  state.content[3].text = caseStudy.outcomes[0].description;
  state.content[4].text = caseStudy.impacts[0].description;
  state.content[5].text = caseStudy.backstory.content;
  state.content[6].text = caseStudy.goal.content;
  state.content[7].text = caseStudy.gap.content;
  state.content[8].text = caseStudy.gamble.content;
  state.content[9].text = caseStudy.approach;
  state.content[10].text = caseStudy.phases
    .map((phase) => `${phase.title}: ${phase.description}`)
    .join("\n\n");
  state.content[11].text = caseStudy.closing_thoughts;

  return (
    <group ref={group}>
      <Flex
        dir="column"
        position={[-viewport.width / 2, viewport.height / 2, 0]}
        size={[viewport.width, viewport.height, 0]}
        onReflow={handleReflow}
      >
        {state.content.map((props, index) => (
          <Page
            key={index}
            left={!(index % 2)}
            textScaleFactor={scale}
            image={
              index === 5
                ? caseStudy.backstory.img
                : caseStudy.phases[index]
                  ? caseStudy.phases[index].img
                  : caseStudy.featured_img
            }
            onReflow={(w, h) => {
              sizesRef.current[index] = h;
              state.threshold = Math.max(
                4,
                (4 / (15.8 * 3)) *
                  sizesRef.current.reduce((acc, e) => acc + e, 0)
              );
            }}
            {...props}
          />
        ))}
        <Box
          dir="row"
          width="100%"
          height="100%"
          align="center"
          justify="center"
        >
          <Box centerAnchor>
            {state.lines.map((props, index) => (
              <Line key={index} {...props} />
            ))}
            <Text
              bold
              position-z={0.5}
              anchorX="center"
              anchorY="middle"
              fontSize={1.5 * scale}
              lineHeight={1}
              letterSpacing={-0.05}
              color="black"
              maxWidth={(viewport.width / 4) * 3}
            >
              {caseStudy.name}
            </Text>
          </Box>
        </Box>
        <Box
          dir="row"
          width="100%"
          height="100%"
          align="center"
          justify="center"
        >
          <Box>
            <Layercard
              depth={0}
              color="#cccccc"
              textColor="#ffffff"
              text={caseStudy.company}
              boxWidth={bW}
              boxHeight={bH}
              map={texture}
              textScaleFactor={scale}
            />
          </Box>
        </Box>
      </Flex>
    </group>
  );
}

export default function CaseStudyCanvas({ caseStudies }) {
  const scrollArea = useRef();
  const onScroll = (e) => (state.top = e.target.scrollTop);
  useEffect(() => void onScroll({ target: scrollArea.current }), []);
  const [pages, setPages] = useState(0);
  const { theme } = useStore();

  return (
    <>
      <Canvas
        shadows
        raycaster={{ enabled: false }}
        dpr={[1, 2]}
        camera={{ position: [0, 0, 10], far: 1000 }}
        gl={{
          powerPreference: "high-performance",
          alpha: false,
          antialias: false,
          stencil: false,
          depth: false,
        }}
        onCreated={({ gl }) => gl.setClearColor(theme.bodyBgPrimary)}
      >
        <pointLight position={[-10, -10, -10]} intensity={1} />
        <ambientLight intensity={0.4} />
        <spotLight
          castShadow
          angle={0.3}
          penumbra={1}
          position={[0, 10, 20]}
          intensity={5}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
        />
        <Suspense fallback={null}>
          <Content onReflow={setPages} caseStudy={caseStudies[0]} />
        </Suspense>
        <EffectComposer></EffectComposer>
        <OrbitControls />
      </Canvas>
      <div
        className="scrollArea"
        ref={scrollArea}
        onScroll={onScroll}
        onPointerMove={(e) =>
          (state.mouse = [
            (e.clientX / window.innerWidth) * 2 - 1,
            (e.clientY / window.innerHeight) * 2 - 1,
          ])
        }
      >
        <div style={{ height: `${pages * 100}vh` }} />
      </div>
      <Loader />
    </>
  );
}

// src/Classes.js

import { gsap } from "gsap";

const friction = 0.99;

export class Player {
  constructor(x, y, radius, color) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.color = color;
    this.velocity = {
      x: 0,
      y: 0,
    };
    this.powerUp = null;
    this.maxSpeed = 8; // Maximum speed for the player
    this.lastShot = 0; // Track last shot time
    this.minTimeBetweenShots = 200; // Minimum time between shots when holding (250ms = 4 shots per second)
  }

  draw(context) {
    context.beginPath();
    context.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    context.fillStyle = this.color;
    context.fill();
  }

  update(context, canvas) {
    this.draw(context);

    // Apply friction
    this.velocity.x *= 0.99;
    this.velocity.y *= 0.99;

    // Clamp velocity to max speed
    const currentSpeed = Math.sqrt(
      this.velocity.x * this.velocity.x + this.velocity.y * this.velocity.y
    );

    if (currentSpeed > this.maxSpeed) {
      const angle = Math.atan2(this.velocity.y, this.velocity.x);
      this.velocity.x = Math.cos(angle) * this.maxSpeed;
      this.velocity.y = Math.sin(angle) * this.maxSpeed;
    }

    // Collision detection for X axis
    if (
      this.x + this.radius + this.velocity.x <= canvas.width &&
      this.x - this.radius + this.velocity.x >= 0
    ) {
      this.x += this.velocity.x;
    } else {
      this.velocity.x = 0;
    }

    // Collision detection for Y axis
    if (
      this.y + this.radius + this.velocity.y <= canvas.height &&
      this.y - this.radius + this.velocity.y >= 0
    ) {
      this.y += this.velocity.y;
    } else {
      this.velocity.y = 0;
    }
  }

  canShoot() {
    const now = Date.now();
    if (now - this.lastShot >= this.minTimeBetweenShots) {
      this.lastShot = now;
      return true;
    }
    return false;
  }
}

export class Projectile {
  constructor(x, y, radius, color, velocity) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.color = color;
    this.velocity = velocity;
  }

  draw(context) {
    context.beginPath();
    context.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    context.fillStyle = this.color;
    context.fill();
  }

  update(context) {
    this.draw(context);
    this.x = this.x + this.velocity.x;
    this.y = this.y + this.velocity.y;
  }
}

export class Enemy {
  constructor(x, y, radius, color, velocity) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.color = color;
    this.velocity = velocity;
    this.type = "Linear";
    this.radians = 0;
    this.center = { x, y };

    if (Math.random() < 0.5) {
      this.type = "Homing";
      if (Math.random() < 0.5) {
        this.type = "Spinning";
        if (Math.random() < 0.5) {
          this.type = "Homing Spinning";
        }
      }
    }
  }

  draw(context) {
    context.beginPath();
    context.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    context.fillStyle = this.color;
    context.fill();
  }

  update(context, player, canvasWidth, canvasHeight) {
    this.draw(context);

    if (this.type === "Homing") {
      if (player) {
        const angle = Math.atan2(player.y - this.y, player.x - this.x);
        this.velocity.x = Math.cos(angle);
        this.velocity.y = Math.sin(angle);
      }

      this.x += this.velocity.x;
      this.y += this.velocity.y;
    } else if (this.type === "Spinning") {
      this.radians += 0.1;
      this.center.x += this.velocity.x;
      this.center.y += this.velocity.y;

      this.x = this.center.x + Math.cos(this.radians) * 30;
      this.y = this.center.y + Math.sin(this.radians) * 30;
    } else if (this.type === "Homing Spinning") {
      this.radians += 0.1;

      if (player) {
        const angle = Math.atan2(
          player.y - this.center.y,
          player.x - this.center.x
        );
        this.velocity.x = Math.cos(angle);
        this.velocity.y = Math.sin(angle);
      }

      this.center.x += this.velocity.x;
      this.center.y += this.velocity.y;

      this.x = this.center.x + Math.cos(this.radians) * 30;
      this.y = this.center.y + Math.sin(this.radians) * 30;
    } else {
      // Linear Movement
      this.x = this.x + this.velocity.x;
      this.y = this.y + this.velocity.y;
    }
  }
}

export class Particle {
  constructor(x, y, radius, color, velocity) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.color = color;
    this.velocity = velocity;
    this.alpha = 1;
  }

  draw(context) {
    context.save();
    context.globalAlpha = this.alpha;
    context.beginPath();
    context.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    context.fillStyle = this.color;
    context.fill();
    context.restore();
  }

  update(context) {
    this.draw(context);
    this.velocity.x *= friction;
    this.velocity.y *= friction;
    this.x = this.x + this.velocity.x;
    this.y = this.y + this.velocity.y;
    this.alpha -= 0.01;
  }
}

export class BackgroundParticle {
  constructor({ position, radius = 3, color }) {
    this.position = position;
    this.radius = radius;
    this.color = color;
    this.alpha = 0.05;
  }

  draw(context) {
    context.save();
    context.globalAlpha = this.alpha;
    context.beginPath();
    context.arc(this.position.x, this.position.y, this.radius, 0, Math.PI * 2);
    context.fillStyle = this.color;
    context.fill();
    context.restore();
  }
}

export class PowerUp {
  constructor({
    position = { x: 0, y: 0 },
    velocity = { x: 0, y: 0 },
    radius = 15,
    color = "#FFD700",
  }) {
    this.position = position;
    this.velocity = velocity;
    this.radius = radius;
    this.color = color;
    this.alpha = 1;

    // Create pulsing effect
    gsap.to(this, {
      alpha: 0.3,
      duration: 0.5,
      repeat: -1,
      yoyo: true,
      ease: "linear",
    });
  }

  draw(context) {
    context.save();
    context.globalAlpha = this.alpha;
    context.beginPath();
    context.arc(this.position.x, this.position.y, this.radius, 0, Math.PI * 2);
    context.fillStyle = this.color;
    context.fill();

    // Add a lightning bolt symbol inside the circle
    context.beginPath();
    const x = this.position.x;
    const y = this.position.y;
    const size = this.radius * 0.8;

    // Draw simplified lightning bolt
    context.moveTo(x - size / 3, y - size / 2);
    context.lineTo(x + size / 4, y - size / 6);
    context.lineTo(x - size / 4, y + size / 6);
    context.lineTo(x + size / 3, y + size / 2);

    context.strokeStyle = "#FFF";
    context.lineWidth = 2;
    context.stroke();
    context.restore();
  }

  update(context) {
    this.draw(context);
    this.position.x += this.velocity.x;
  }
}

// components/Dashboards/HomeDashboard.jsx

import React, { useMemo } from "react";
import { useViewport } from "../../context/ViewportContext";
import {
  DashboardWrapper,
  BentoGrid,
  Intro,
  Pitch,
  SmallText,
  LargeText,
  Item1,
  Item2,
  Item3,
  Item4,
  Item5,
  Item6,
  Item7,
  Item8,
} from "./HomeDashboard.style";
import useStore from "../../stores/store";

function HomeDashboard() {
  const { theme } = useStore();
  const viewport = useViewport();
  const { width, height, isLandscape } = viewport;

  return (
    <DashboardWrapper disableBackground className="DASHBOARD_WRAPPER">
      <BentoGrid className="BENTOGRID">
        <Item1 disableBackground>
          <LargeText>Hi, I'm David</LargeText>
        </Item1>

        <Item8 disableBackground>
          <SmallText theme={theme}>I support businesses with</SmallText>
          <LargeText theme={theme}>Creative Development</LargeText>
          <LargeText theme={theme}>& Marketing Services</LargeText>
        </Item8>
      </BentoGrid>
    </DashboardWrapper>
  );
}

export default HomeDashboard;
